<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox errorpage">
        <h1><i class="fas fa-exclamation-triangle"></i>Error 404</h1>
        <h3>
          죄송합니다. 현재 찾으시는 페이지를 찾을 수 없습니다.<br />새로고침하거나
          이전 페이지로 이동 후 다른 페이지를 클릭해주세요.
        </h3>
        <div class="buttonWrap">
          <router-link to="/"
            ><button class="point large">이전페이지로 이동</button></router-link
          >
          <router-link to="/">
            <button class="large">홈으로 이동</button>
          </router-link>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
//
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
};
</script>
